<template>
  <q-dialog v-model="isOpen" class="modal-md">
    <q-card style="overflow: visible;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('New invoice') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <invoice
          has-close
          :account="options.account"
          :hide-account="!!options.account"
          @cancel="close"
          @submit="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import Invoice from '../invoice/Invoice.vue'

export default {
  name: 'InvoiceModal',
  emits: ['submit'],
  components: {
    Invoice
  },
  data () {
    return {
      isOpen: false,
      options: {}
    }
  },
  methods: {
    close () {
      this.isOpen = false
    },
    open (options = {}) {
      this.isOpen = true
      this.options = options
    },
    save (item) {
      this.$emit('submit', item)
      this.close()
    }
  }
}
</script>
