<template>
  <q-dialog v-model="isOpen">
    <q-card  style="min-width: 64vw;">
      <q-card-section class="row items-center">
        <div class="text-h6 text-center q-mr-sm">
          {{ $t('Legal Entities') }}
        </div>

        <q-space />

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <q-card-section class="q-pb-none">
        <form-builder ref="formBuilder" :schema="schema" />
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          color="positive"
          :label="$t('Ok')"
          no-caps
          @click="handleOk"
        />

        <q-btn
          color="negative"
          :label="$t('Cancel')"
          no-caps
          @click="handleClose"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>

import {mapGetters, mapActions, mapMutations} from "vuex";

export default {
  name: 'ConfirmPaymentModal',
  emits: ['submit'],
  data () {
    return {
      isOpen: false,
      result: '',
      invoice: null,
    }
  },
  computed: {
    ...mapGetters([
        'appOptions',
        'legalEntity'
    ]),
    schema () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Source'),
                value: this.result,
                required: true,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }
                  query.filter = [
                      {
                          type: 'leftjoin',
                          field: 'integration',
                          parentAlias: 's',
                          alias: 'ii',
                          conditionType: 'WITH',
                          condition: 'ii.id=s.integration'
                      },
                      {
                          type: 'innerjoin',
                          field: 'categories',
                          parentAlias: 'ii',
                          alias: 'ic'
                      },
                      {
                          type: 'eq',
                          field: 'name',
                          value: 'Payments',
                          alias: 'ic'
                      }
                  ]
                    console.log(this.legalEntity)
                  return this.$service.iSource.getAll(query)
                },
                onChange: (rate) => {
                    this.result = rate
                    this.updateLegalEntityEav({ 'integrations-source-id': rate.id })
                    this.updateLegalEntityEav({ 'integrations-source-type': rate.handler })
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
      ...mapActions([
          'saveLegalEntity'
      ]),
      ...mapMutations([
        'upsertInvoice',
        'updateLegalEntityEav'
      ]),
      handleSubmit (offer) {
          this.$emit('submit', offer)
          this.close()
      },
      close () {
          this.isOpen = false
      },
      open (invoice) {
        this.invoice = invoice
        this.isOpen = true
      },
      handleClose () {
          this.isOpen = false
          this.resolve(false)
      },
      handleOk () {
        if(this.invoice?.state === 'pending') {
          this.$service.invoice.save({ state: 'confirmed' }, this.invoice.id)
            .then(item => {
              this.saveLegalEntity()
              this.$emit('submit', item)
              this.upsertInvoice(item)
              this.isOpen = false
            })
        } else {
          this.saveLegalEntity()
            .then(()=>{
              this.isOpen = false
            })
        }

      }
  }
}
</script>