<template>
  <div>
    <action-header
      :actions-model="headerModel"
      :page="page"
    />

    <q-tabs
      v-if="account"
      v-model="tab"
      class="text-grey"
      active-color="primary"
      indicator-color="primary"
      align="justify"
      inline-label
      narrow-indicator
    >
      <q-tab
        name="account"
        :label="$t('Account')"
        icon="account_circle"
      />

      <q-tab
        name="transactions"
        :label="$t('Transactions')"
        icon="paid"
      />

      <q-tab
        name="actions"
        :label="$t(account.type === 'deposit' ? 'Invoices' : 'Withdraws')"
        icon="contactless"
      />

      <q-tab
        v-if="account._embedded.rate"
        name="rates"
        :label="$t('Rates')"
        icon="savings"
      />
    </q-tabs>

    <q-separator />

    <div
      v-if="warning"
      class="bg-warning q-pa-sm q-mb-sm text-center"
    >
      {{ $t(warning) }}
    </div>

    <div v-if="account">
      <component
        :is="components[account.type]"
        v-if="account"
        :tab="tab"
        :key="account.id"
        :account="account"
      />
    </div>

    <div
      v-else-if="!account && isLoading"
      class="row justify-center items-center text-center q-my-md text-subtitle1"
      style="min-height: 80vh;"
    >
      <q-spinner
        color="primary"
        size="4rem"
        class="q-mb-md"
      />
    </div>

    <div
      v-else-if="!account && !isLoading"
      class="row justify-center items-center text-center q-my-md text-subtitle1"
      style="min-height: 80vh;"
    >
      <div>
        {{ $t('You don\'t have any accounts yet.') }}
      </div>
    </div>

    <q-dialog v-model="accountModal">
      <q-card>
        <q-card-section>
          <account
            :type="requiredType"
            @close="handleCloseAccount"
            @save="handleAccount"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Components
import AccountPayment from './AccountPayment.vue'
import AccountDeposit from './AccountDeposit.vue'
import Account from '../../components/account/Account.vue'
import ActionHeader from '../../components/action-header/ActionHeader.vue'

export default {
  name: 'AccountDetails',
  emits: ['user'],
  components: {
    AccountPayment,
    AccountDeposit,
    Account,
    ActionHeader
  },
  data () {
    return {
      tab: 'account',
      components: {
        deposit: 'account-deposit',
        payment: 'account-payment'
      },
      requiredType: 'payment',
      accountModal: false,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'account',
      'accounts',
      'accountsLoading',
      'totalAccountsNumber',
      'history',
      'currentUser',
      'isClient'
    ]),
    page () {
      return {
        id: this.account && this.account.id,
        name: this.account && this.account.id
          ? this.$t('Account')
          : this.$t('New account')
      }
    },
    headerModel () {
      if (!this.account) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'q-mr-md hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: () => {
                this.$router.back()
              }
            }
          ]
        },
        {
          section: 'Title',
          className: 'mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              className: 'text-white',
              value: this.account.id
                ? this.$t('Account ID: ') + this.account.id
                : this.$t('New account'),
              additionalValue: this.account && `${this.$t('Type')}: ${this.$t(this.account.type)}`
            }
          ]
        }
      ]
    },
    isEnabledBack () {
      return this.currentUser.roles.find(({ id }) => id === 3 || id === 29)
    },
    buttonsForCreate () {
      return [
        {
          id: 'deposit',
          label: 'Create a deposit account',
          onClick: () => {
            this.requiredType = 'deposit'
            this.accountModal = true
          }
        },
        {
          id: 'payment',
          label: 'Create a cash on delivery payments account',
          onClick: () => {
            this.requiredType = 'payment'
            this.accountModal = true
          }
        }
      ].filter(btn => {
        return !this.accounts.find(account => {
          return account.type === btn.id
        })
      })
    },
    warning () {
      if (!this.account) {
        return ''
      }

      const depositAccount = this.accounts.find(account => account.type === 'deposit')

      if (!depositAccount) {
        return 'Deposit account not exist and you can\'t use withdraw form!'
      }

      if (depositAccount.creditLimit < 0) {
        return 'Credit limit on your deposit account is less than 0. Account can be blocked soon!'
      }

      const depositBalance = Number(depositAccount.balance || 0)

      if (depositBalance < 0) {
        return 'You have debt!'
      }

      return ''
    }
  },
  mounted () {
    this.isLoading = true

    this.$service.accounts.get(this.$route.params.id)
      .then(account => {
        const query = {
          per_page: 25,
          page: 1,
          filter: [
            { field: 'owner', type: 'eq', value: account._embedded.owner.id },
          ]
        }
    
        return this.loadAccounts(query)
          .then(() => {
            this.setCurrentAccount(account)
            return account
          })
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  unmounted () {
    this.setAccount(null)
  },
  methods: {
    ...mapActions([
      'loadAccounts',
      'loadRequestHistory'
    ]),
    ...mapMutations([
      'setAccount',
      'upsertAccount'
    ]),
    handleBackToUsers () {
      this.$emit('user', null)
    },
    handleAccount (account) {
      this.upsertAccount(account)
    },
    setCurrentAccount (user) {
      this.setAccount(user)
    },
    handleCloseAccount () {
      this.accountModal = false
    }
  }
}
</script>
