<template>
  <div v-if="payment">
    <div v-if="hasClose">
      <form-builder :schema="schema" />

      <div class="text-center q-mt-sm">
        <q-btn
          color="dark"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Cancel')"
          no-caps
          @click="cancel"
        />

        <q-btn
          color="light-blue-9"
          :label="$t('Save')"
          no-caps
          @click="save"
        />
      </div>
    </div>

    <div v-else>
      <div class="row items-center">
        <q-input
          dense
          filled
          bg-color="white"
          color="black"
          standout="text-black"
          class="col text-black"
          :model-value="payment.value"
          :label="$t('Enter amount')"
          @change="handleUpdate"
        >
          <template v-slot:append>
          </template>
        </q-input>

        <q-btn
          color="green-6"
          class="q-ml-sm"
          :label="$t('Create')"
          no-caps
          @click="save"
        />
      </div>

      <div
        v-if="message"
        class="q-pa-sm text-caption text-white"
      >
        {{ $t(message) }}
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Withdraw',
  emits: ['cancel', 'submit'],
  props: {
    hideAccount: {
      type: Boolean,
      default () {
        return false
      }
    },
    hasClose: {
      type: Boolean,
      default () {
        return false
      }
    },
    account: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      message: null
    }
  },
  computed: {
    ...mapGetters([
      'payment'
    ]),
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                if: !this.hideAccount,
                type: 'multiselect',
                label: this.$t('Account'),
                value: this.payment._embedded.account,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                disabled: !!this.account,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.accounts.getAll(query)
                },
                onChange: (account) => {
                  const owner = account
                    ? account._embedded.owner
                    : null

                  this.updatePaymentEmbedded({ account, owner })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.payment.value,
                label: this.$t('Enter amount'),
                wrapperStyleClasses: 'col q-pa-xs',
                required: true,
                onChange: value => {
                  this.handleUpdate(value)
                }
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    if (!this.payment) {
      this.setNewPayment()
    }

    if (this.account) {
      this.updatePayment({
        _embedded: {
          ...this.payment._embedded,
          account: this.account,
          owner: this.account._embedded.owner
        }
      })
    }
  },
  methods: {
    ...mapActions([
      'savePayment'
    ]),
    ...mapMutations([
      'setNewPayment',
      'setPayment',
      'updatePayment',
      'addErrorNotification',
      'updatePaymentEmbedded',
      'upsertPayment'
    ]),
    handleUpdate (value) {
      this.updatePayment({ value: Number(value || 0).toFixed(2) })
    },
    save () {
      if (Number(this.payment.value) <= 0) {
        this.message = 'Please enter the amount and then tap on add button.'
        this.addErrorNotification('Please enter the amount and then tap on add button.')
        return
      }

      return this.savePayment()
        .then(item => {
          this.upsertPayment(item)
          this.$emit('submit', item)
          this.message = 'Success! The withdraw has been created!'
        })
        .catch(err => {
          this.message = err.message
        })
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
