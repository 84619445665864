<template>
  <q-card class="fit border">
    <q-card-section class="row items-center q-py-sm border-bottom">
      <slot name="header" />

      <q-space />

      <q-btn
        :color="color"
        text-color="white"
        size="sm"
        class="q-mr-sm"
        :label="$t('Filter')"
        no-caps
        unelevated
        @click="toggleFilter"
      />

      <slot />
    </q-card-section>

    <filter-collapse
      :is-open="isOpen"
      :options="{
        defaultFilter: filters,
        fields: activatedFields,
        values: {
          states: statuses
        },
        style: {
          noGroups: true,
          flat: true,
          allOnThird: true
        }
      }"
      @submit="handleFiltersSubmit"
      @close="toggleFilter"
    />
    <br>
    <q-separator />
    <q-table
      row-key="id"
      :rows-per-page-label="$t('Rows per page')"
      :rows="invoices"
      :columns="columns"
      v-model:pagination="pagination"
      :loading="invoicesLoading"
      :filter="filter"
      :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
      virtual-scroll
      binary-state-sort
      flat
      @request="onRequest"
    >
      <template v-slot:body="props">
        <invoice-row :data="props" @submit="handleSubmit" />
      </template>

      <template v-slot:bottom-row>
        <q-tr>
          <q-td class="border-top border-right text-center">
            <strong>
              {{ $t('Total') }}
            </strong>
          </q-td>

          <q-td class="border-top"/>

          <q-td class="border-top"/>

          <q-td class="border-top">
            <strong>
              {{ totalPrice }}
            </strong>
          </q-td>

          <q-td class="border-top"/>

          <q-td class="border-top"/>

          <q-td class="border-top"/>

          <q-td class="border-top"/>
        </q-tr>
      </template>

    </q-table>
  </q-card>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Components
import FilterCollapse from '../filters/FilterCollapse.vue'
import InvoiceRow from './InvoiceRow.vue'

export default {
  name: 'Invoices',
  emits: ['submit'],
  components: {
    InvoiceRow,
    FilterCollapse
  },
  props: {
    account: {
      type: Object,
      default () {
        return null
      }
    },
    accountId: {
      type: Number,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      isOpen: true,
      filter: '',
      stripe: null,
      totalPrice: 0,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 50
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Value'),
          name: 'value',
          align: 'left'
        },
        {
          label: this.$t('History'),
          name: 'history',
          align: 'center'
        },
        {
          label: this.$t('Download'),
          name: 'documents',
          align: 'center'
        },
        {
          label: this.$t('Payee'),
          name: 'payee',
          align: 'center'
        },
        {
          label: this.$t('Payment'),
          name: 'payment',
          align: 'center'
        }
      ],
      stateColors: {
        pending: 'info',
        conditionally_paid: 'yellow',
        confirmed: 'green',
        paid: 'success',
        deleted: 'danger'
      },
      statuses: [
        { id: 'pending', title: 'Pending' },
        { id: 'conditionally_paid', title: 'Conditionally_paid' },
        { id: 'confirmed', title: 'Confirmed' },
        { id: 'paid', title: 'Paid' }
      ],
      activatedFields: [
        'id',
        'state',
        'created.from',
        'created.to'
      ],
      filters: [
        { type: 'eq', field: 'account', value: this.accountId }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'invoices',
      'totalInvoicesNumber',
      'invoicesLoading',
      'appOptions'
    ]),
    color () {
      return this.filters.filter(x => x.field !== 'account').length > 0
        ? 'light-blue-7'
        : 'dark'
    }
  },
  mounted () {
    if (!this.accountId) {
      return
    }
    this.loadPayIntegration()
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadInvoices'
    ]),
    loadPayIntegration () {
        const query = {
            per_page: 25,
            page: 1,
            filter: [
                {type: 'eq', field: 'name', value: 'Stripe'}
            ],
        }

        // query.filter = this.filter
        //
        // // if(this.getTypeUser() === 'client'){
        // //   query.filter.push({type: "in", field: "id", values: [259, 260]})
        // // }
        //
        // if (this.tab !== 'all') {
        //     query.filter.push({ type: 'eq', field: 'category', value: this.tab })
        // }

        this.isLoading = true

        return this.$service.iIntegrationService.getAll(query)
          .then(({ items, page, totalPages }) => {
              if(items) {
                  this.stripe = items[0]
              }
          })
    },
    handleFiltersSubmit (filter) {
      this.filters = filter
      this.isOpen = false
      return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilter () {
      this.isOpen = !this.isOpen
    },
    handleSubmit (item) {
      this.$emit('submit', item)
    },
    loadDefaultItems () {
      const pagination = {
        per_page: 5,
        page: 1,
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      }

      return this.onRequest({ pagination })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      query.filter = this.filters
      query.criteria = { account: this.accountId }

      if (query['order-by']) {
        query.sort = query['order-by'].reduce((acc, order) => {
          if (order.field === 'created') {
            acc.id = order.direction
          } else {
            acc[order.field] = order.direction
          }

          return acc
        }, {})
      }

      return this.loadInvoices(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.totalPrice = 0

          items.forEach(e=>{
            this.totalPrice += e.value
            return
          })

          return { items, totalPages, page, totalItems }
        })
    }
  }
}
</script>
