<template>
  <q-dialog v-model="isOpen">
    <q-card  style="min-width: 64vw;">
      <q-card-section class="row items-center">
        <div class="text-h6 text-center q-mr-sm">
            {{ $t('Legal Entities') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>



      <q-card-section class="text-center">
        <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
          :loading="loading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
            >
              <q-td
                key="number"
                :props="props"
                auto-width
              >
                 {{ props.rowIndex +  1 }}
              </q-td>

              <q-td
                key="id"
                :props="props"
              >
                  {{ props.row.id }}
              </q-td>

              <q-td
                key="owner"
                :props="props"
              >
                <span v-if="props.row._embedded && props.row._embedded.owner">
                  {{ `${props.row._embedded.owner.name} (${props.row._embedded.owner.email})`}}
                </span>
                  <span v-else>
                      {{'--'}}
                  </span>
              </q-td>

              <q-td
                  key="state"
                  :props="props"
              >
                <span>
                  {{ props.row.state }}
                </span>

              </q-td>

              <q-td
                  key="name"
                  :props="props"
              >
                  {{ props.row.name}}
              </q-td>

              <q-td
                  key="contactNumber"
                  :props="props"
              >
                  {{ props.row.contractNumber || '--'}}
              </q-td>

              <q-td
                  key="tax"
                  :props="props"
              >
                  {{ props.row.tax }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
  <confirm-payment-modal ref="confirmPaymentModal" @submit="handleSubmitInvoice"/>
</template>

<script>

import {buildQuery} from "../../utils/query-utils";
import Search from "../../components/search/Search.vue";
import {mapGetters, mapMutations} from "vuex";
import FilterCollapse from "../../components/filters/FilterCollapse.vue";
import ConfirmPaymentModal from "../../components/modals/ConfirmPaymentModal.vue";

export default {
  name: 'LegalEntitiesModal',
  emits: ['submit'],
  components: {
    ConfirmPaymentModal,
    FilterCollapse,
    Search
  },
  data () {
    return {
      isOpen: false,
      filterParams: [],
      items: [],
      invoice: null,
      filter: '',
      loading: false,
      isOpenFilter: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left',
        },
        {
          label: this.$t('State'),
          name: 'state',
          align: 'left'
        },
         {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Contact Number'),
          name: 'contactNumber',
          align: 'left'
        },
        {
          label: this.$t('Tax'),
          name: 'tax',
          align: 'left'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    filterBtnText () {
      return this.filterParams && this.filterParams.length > 0
        ? this.$t('Filtered: ' + this.pagination.rowsNumber)
        : this.$t('Filter')
    }
  },
  methods: {
    ...mapMutations([
        'setLegalEntity'
    ]),
    onRowDblClick (item) {
        this.setLegalEntity(item)
        this.$refs.confirmPaymentModal.open(this.invoice)
    },
    handleFiltersSubmit (filter) {
        this.onRequest({ pagination: { filter, page: 1 } })
        this.isOpenFilter = false
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    handleSearch (search) {

    },
    handleSubmit (offer) {
      this.$emit('submit', offer)
      this.close()
    },
    close () {
      this.isOpen = false
    },
    open (invoice) {
      this.invoice = invoice
      this.isOpen = true
      this.onRequest({})
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = [
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'eq', field: 'type', value: 'company'}
      ]

      this.loading = true
      return this.$service.legalEntity.getAll(query)
          .then(({items, page, totalItems})=> {
              this.pagination = {
                ...this.pagination,
                page,
                rowsNumber: totalItems
              }
              this.isOpen = true
              this.items = items
              this.loading = false
          })
    },
    handleSubmitInvoice (item) {
      this.$emit('submit', item)
    }
  }
}
</script>