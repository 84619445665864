<template>
  <form-builder :schema="schema" />
</template>

<script>
export default {
  name: 'Transaction',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      entityTypes: [
        { id: 'Orderadmin\\Products\\Entity\\AbstractOrder', name: 'Order' },
        { id: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest', name: 'Shipment' },
        { id: 'Orderadmin\\Notifications\\Entity\\Crm\\Interaction', name: 'Interaction' },
        { id: 'Orderadmin\\Storage\\Entity\\Warehouse', name: 'Warehouse' },
        { id: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance', name: 'Receiving' },
        { id: 'invoice', name: 'Invoice' },
        { id: 'pickup', name: 'Pickup' },
        { id: 'report', name: 'Report' }
      ],
      tax: 0,
      isEnabledValue: true,
      model: {
        account: null,
        rateModifier: null,
        entityType: null,
        invoice: null,
        currency: null,
        entity: null,
        extId: null,
        value: null,
        transactionDate: null,
        comment: null
      }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                if: !this.data.account,
                type: 'multiselect',
                label: this.$t('Account'),
                field: 'account',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.model.account,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'state', values: ['active','blocked'] }
                    ]
                  }

                  return this.$service.accounts.getAll(query)
                },
                onChange: value => {
                  this.model.account = value
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Fee'),
                field: 'rateModifier',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                value: this.model.rateModifier,
                required: true,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    if (row.type === 'fixed') {
                      return `${row.name} (${this.$t('ID')}: ${row.id}, ${this.$t('Type')}: ${row.type}, ${this.$t('Value')}: ${row.value || 0})`
                    }

                    return `${row.name} (${this.$t('ID')}: ${row.id}, ${this.$t('Type')}: ${row.type})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' },
                      { type: 'notin', field: 'type', values: ['managed']}
                    ]
                  }

                  if (this.model.account && this.model.account._embedded && this.model.account._embedded.rate) {
                    query.filter.push({ type: 'eq', field: 'rate', value: this.model.account._embedded.rate.id })
                  }

                  return this.$service.rateModifier.getAll(query)
                },
                onChange: value => {
                  this.model.rateModifier = value
                  this.isEnabledValue = (value.type === 'fixed' && !value.value) || value.type === 'percent'

                  if (value.type === 'fixed') {
                    this.model.value = value.value
                  }
                }
              },
              {
                type: 'date',
                label: this.$t('Transaction date'),
                field: 'transactionDate',
                value: this.model.transactionDate,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: value => {
                  this.model.transactionDate = value
                }
              },
              {
                // For now we dont need it
                if: false,
                type: 'multiselect',
                label: this.$t('Invoice'),
                field: 'invoice',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                value: this.model.invoice,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row._embedded.payee.name} - ${row.value} - ${row.state} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  if (this.model.account) {
                    query.filter.push({ type: 'eq', field: 'account', value: this.model.account.id })
                    query.criteria = { account: this.model.account.id }
                  }

                  return this.$service.invoice.getAll(query)
                },
                onChange: value => {
                  this.model.invoice = value
                }
              },
              {
                type: 'select',
                label: this.$t('Entity type'),
                field: 'entityType',
                value: this.model.entityType,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.entityTypes,
                hasResetBtn: true,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return this.$t(row.name)
                  }

                  if (!row) {
                    return ''
                  }

                  const entity = this.entityTypes.find(x => x.id === row)

                  return entity
                    ? this.$t(entity.name)
                    : row
                },
                onChange: value => {
                  this.model.entityType = value && value.id

                  if (!this.model.entityType) {
                    this.model.entity = ''
                  }
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Entity ID'),
                field: 'entity',
                value: this.model.entity,
                disabled: !this.model.entityType,
                required: !!this.model.entityType,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: value => {
                  this.model.entity = value
                }
              },
              {
                disabled: !!this.model.rateModifier && !this.isEnabledValue,
                type: 'input',
                inputType: 'number',
                label: this.$t('Value'),
                field: 'value',
                value: this.model.value,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                required: true,
                onChange: value => {
                  this.model.value = value
                  this.model.tax = (this.tax * (value || 0)).toFixed(2)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Tax'),
                field: 'tax',
                value: this.model.tax,
                disabled: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: value => {
                  this.model.tax = value
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('External Id'),
                field: 'extId',
                value: this.model.extId,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: value => {
                  this.model.extId = value
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Comment'),
                field: 'comment',
                value: this.model.comment,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: value => {
                  this.model.comment = value
                }
              },
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.tax = this.data.tax || 0

    this.model = {
      ...this.model,
      ...this.data,
      tax: 0
    }
  },
  methods: {
    getErrors () {
      return this.schema.groups[0].fields.reduce((acc, field) => {
        if (field.required && !field.value) {
          acc.push(`${field.label} is required!`)
        }

        return acc
      }, [])
    },
    getModel () {
      return this.model
    }
  }
}
</script>
