<template>
  <div
    v-if="isLoading"
    class="row items-center justify-center"
    style="min-height: 200px;"
  >
    <q-spinner
      color="primary"
      size="4rem"
      class="q-mb-md"
    />
  </div>

  <div v-else>
    <q-card
      v-for="rate in items"
      :key="rate.id"
      class="q-ma-sm"
    >
      <rate-modifier
        :model="rate"
        :is-custom="!codes.includes(rate.code)"
        :no-actions="noActions"
        static-mode
      />
    </q-card>
  </div>
</template>

<script>
// Components
import RateModifier from './RateModifier.vue'

export default {
  name: 'RateModifiersRow',
  components: {
    RateModifier
  },
  props: {
    noActions: {
      type: Boolean,
      default () {
        return false
      }
    },
    extFilter: {
      type: Array,
      default () {
        return []
      }
    },
    accountRate: {
      type: Object,
      default () {
        return null
      }
    },
    expand: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isLoading: false,
      items: [],
      codes: [
        'acceptance.taxation',
        'warehousing.taxation',
        'assembling.taxation',
        'picking.taxation',
        'shipping.taxation',
        'return.taxation'
      ]
    }
  },
  watch: {
    expand (newVal) {
      if (newVal && this.items.length <= 0) {
        this.loadItems()
      }
    }
  },
  mounted () {
    if (this.expand) {
      this.loadItems()
    }
  },
  methods: {
    loadItems () {
      this.isLoading = true
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' },
          ...this.extFilter,
        ]
      }

      if (this.accountRate) {
        query.filter.push({ type: 'eq', field: 'rate', value: this.accountRate.id })
      }

      return this.$service.rateModifier.getAll(query)
        .then(data => {
          this.items = data.items
          return data
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
