<template>
  <div v-if="account">
    <q-tab-panels :model-value="tab" animated>
      <q-tab-panel name="account" class="row">
        <div class="col-12 col-md q-pa-sm">
          <q-card class="bg-info fit">
            <q-card-section>
              <h6 class="q-my-none q-mb-sm text-weight-bold text-center text-white">
                {{ $t('Account Info') }}
              </h6>

              <div class="q-mb-sm">
                <label class="text-subtitle2 text-weight-bold">
                  {{ $t('Account name') }}
                </label>

                <div class="q-px-md q-py-sm bg-white text-dark text-subtitle1 text-weight-bold rounded q-mt-xs row items-center">
                  {{ account.name || $t('No name') }}
                </div>
              </div>

              <div class="q-mb-sm">
                <label class="text-subtitle2 text-weight-bold">
                  {{ $t('Account ID') }}
                </label>

                <div class="q-px-md q-py-sm bg-white text-dark text-subtitle1 text-weight-bold rounded q-mt-xs row items-center">
                  {{ account.id }}

                  <q-space />

                  <span
                    class="text-caption text-weight-regular clickable"
                    @click="copy(account.id)"
                  >
                    {{ $t('Copy') }}
                  </span>
                </div>
              </div>

              <div>
                <label class="text-subtitle2 text-weight-bold">
                  {{ $t('Billing ID') }}
                </label>

                <div class="q-px-md q-py-sm bg-white text-dark text-subtitle1 text-weight-bold rounded q-mt-xs row items-center">
                  {{ account._embedded && account._embedded.rate && account._embedded.rate.id || $t('No billing account') }}

                  <q-space />

                  <span
                    v-if="account._embedded && account._embedded.rate && account._embedded.rate.id"
                    class="text-caption text-weight-regular clickable"
                    @click="copy(account._embedded.rate.id)"
                  >
                    {{ $t('Copy') }}
                  </span>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>

        <div class="col-12 col-md q-pa-sm">
          <q-card class="text-white bg-primary q-mb-md">
            <q-card-section>
              <div class="q-my-none q-mb-xxs text-right">
                <q-icon name="info"  >
                  <q-tooltip >
                    Balance update inside Orderadmin does immediately, so that all charges will be made right after your Invoice has the «Paid» status. However a visual balance update happens ones in 30 minutes.
                  </q-tooltip>
                </q-icon>
              </div>

              <h6 class="q-my-none q-mb-sm text-weight-bold text-center">
                {{ $t('Balance') }}
              </h6>

              <div class="q-px-md q-py-sm bg-white text-dark text-h4 text-center text-weight-bold rounded q-mt-xs">
                {{ Number(account.balance).toFixed(2) }}
              </div>
            </q-card-section>
          </q-card>

          <q-card class="bg-accent text-white" style="min-height: 150px;">
            <q-card-section>
              <h6 class="q-my-none q-mb-sm text-weight-bold text-center">
                {{ $t('New invoice') }}
              </h6>

              <invoice
                :account="account"
                hide-account
              />
            </q-card-section>
          </q-card>
        </div>
      </q-tab-panel>

      <q-tab-panel name="transactions">
        <transactions
          ref="transactions"
          :account="account"
          flat
          @first-load="handleFirstLoad"
        >
          <q-btn
            v-if="isSupervisior || isAdministrator"
            size="sm"
            color="green-6"
            icon="add"
            unelevated
            @click="handleAddTransaction"
          />
        </transactions>
      </q-tab-panel>

      <q-tab-panel name="actions">
        <invoices
          :account="account"
          :account-id="account.id"
          @submit="handleSubmit"
        >
          <q-btn
            size="sm"
            color="green-6"
            icon="add"
            unelevated
            @click="handleAdd"
          />
        </invoices>
      </q-tab-panel>

      <q-tab-panel v-if="account._embedded.rate" name="rates">
        <rate-modifiers-row
          v-show="isExpand"
          :expand="isExpand"
          :ext-filter="[{ type: 'neq', field: 'type', value: 'percent' }]"
          :account-rate="account._embedded.rate"
          no-actions
        />
      </q-tab-panel>
    </q-tab-panels>

    <transaction-modal ref="transactionModal" @submit="refreshAccount" />

    <invoice-modal ref="invoiceModal" />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import RateModifiersRow from '../../components/account/RateModifiersRow.vue'
import Invoice from '../../components/invoice/Invoice.vue'
import Invoices from '../../components/invoice/Invoices.vue'
import InvoiceModal from '../../components/modals/InvoiceModal.vue'
import TransactionModal from '../../components/modals/TransactionModal.vue'
import Transactions from '../../components/transactions/Transactions.vue'

// Utils
import { doCopy } from '../../utils/copy-utils'

export default {
  name: 'AccountDeposit',
  components: {
    Transactions,
    Invoices,
    RateModifiersRow,
    Invoice,
    TransactionModal,
    InvoiceModal
  },
  props: {
    tab: {
      type: String,
      default () {
        return 'account'
      }
    },
    account: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      isExpand: true
    }
  },
  computed: {
    ...mapGetters([
      'isSupervisior',
      'isAdministrator'
    ])
  },
  methods: {
    ...mapMutations([
      'upsertAccount'
    ]),
    copy (value) {
      doCopy(value)
    },
    handleAdd () {
      this.$refs.invoiceModal.open({
        account: this.account
      })
    },
    handleSubmit () {
      this.$refs.transactions?.loadDefaultItems()

      // Update balance
      this.$service.account.get(this.account.id).then(this.upsertAccount)
    },
    refreshAccount () {
      if (!this.$service.account) {
        return
      }

      this.$service.account.get(this.account.id).then(this.upsertAccount)
    },
    handleAddTransaction () {
      this.$refs.transactionModal.open({
        account: this.account,
        currency: this.account._embedded.rate,
        tax: (this.account._embedded.contractor && this.account._embedded.contractor.tax) || 0,
        state: 'confirmed'
      })
    },
    handleExpand () {
      if (this.account._embedded && this.account._embedded.rate) {
        this.isExpand = !this.isExpand
      }
    },
    handleFirstLoad () {
      if (!this.$refs.graphic) {
        return
      }

      this.$refs.graphic.load()
    }
  }
}
</script>
