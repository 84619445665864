<template>
  <q-card class="fit border">
    <q-card-section class="row items-center q-py-sm border-bottom">
      <slot name="header" />

      <q-space />

      <q-btn
        :color="color"
        text-color="white"
        size="sm"
        class="q-mr-sm"
        :label="$t('Filter')"
        no-caps
        unelevated
        @click="toggleFilter"
      />

      <slot />
    </q-card-section>

    <filter-collapse
      :is-open="isOpen"
      :options="{
        defaultFilter: filters,
        fields: activatedFields,
        values: {
          states: statuses
        },
        style: {
          noGroups: true,
          flat: true,
          allOnThird: true
        }
      }"
      @submit="handleFiltersSubmit"
      @close="toggleFilter"
    />

    <q-table
      row-key="id"
      :rows-per-page-label="$t('Rows per page')"
      :rows="payments"
      :columns="columns"
      v-model:pagination="pagination"
      :loading="paymentsLoading"
      :filter="filter"
      :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
      virtual-scroll
      binary-state-sort
      flat
      @request="onRequest"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td
            key="id"
            :props="props"
            class="text-subtitle1"
          >
            <strong>{{ props.row.id }}</strong>
          </q-td>

          <q-td
            key="state"
            :props="props"
          >
            <q-badge
              :color="stateColors[props.row.state]"
              text-color="dark"
              class="q-pa-sm"
            >
              {{ $t(props.row.state) }}
            </q-badge>
          </q-td>

          <q-td
            key="info"
            :props="props"
          >
            <span v-if="!props.row._embedded.report">-</span>

            <span v-else>
              {{ props.row._embedded.report.name }}<br>
              <strong>{{ $t('Total') }}: {{ props.row._embedded.report.result.total.payment.toFixed(2) }}</strong>
            </span>
          </q-td>

          <q-td
            key="value"
            :props="props"
          >
            {{ props.row.value }}
          </q-td>

          <q-td
            key="date"
            :props="props"
          >
            {{ $moment(props.row.transactionDate.date).format(appOptions.formats.date) }}
          </q-td>

          <q-td
            key="documents"
            :props="props"
          >
            <q-btn
              v-if="props.row.eav['reports-account-payment-report']"
              color="dark"
              text-color="white"
              size="sm"
              :label="$t('Download')"
              @click="printReport(props.row.eav['reports-account-payment-report'])"
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>
   </q-card>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Components
import FilterCollapse from '../filters/FilterCollapse.vue'

export default {
  name: 'Withdraws',
  components: {
    FilterCollapse
  },
  props: {
    owner: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 5
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Info'),
          name: 'info',
          align: 'left'
        },
        {
          label: this.$t('Value'),
          name: 'value',
          align: 'left'
        },
        {
          label: this.$t('Transaction date'),
          name: 'date',
          align: 'left'
        },
        {
          label: this.$t('Download'),
          name: 'documents',
          align: 'left'
        }
      ],
      stateColors: {
        new: 'green',
        confirmed: 'yellow'
      },
      isOpen: true,
      statuses: [
        { id: 'new', title: 'New' },
        { id: 'confirmed', title: 'Confirmed' }
      ],
      activatedFields: [
        'id',
        'state',
        'created.from',
        'created.to'
      ],
      filters: [
        { type: 'eq', field: 'owner', value: this.owner.id }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'payments',
      'paymentsLoading',
      'totalPaymentsNumber',
      'appOptions'
    ]),
    color () {
      return this.filters.filter(x => x.field !== 'owner').length > 0
        ? 'light-blue-7'
        : 'dark'
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadPayments',
      'printReport'
    ]),
    ...mapMutations([
      'setPayment'
    ]),
    handleFiltersSubmit (filter) {
      this.filters = filter
      this.isOpen = false
      return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilter () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      const pagination = {
        per_page: 5,
        page: 1
      }

      return this.onRequest({ pagination })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query['order-by'] = [
        { type: 'field', field: 'created', direction: 'desc' }
      ]
      query.filter = this.filters

      return this.loadPayments(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          return { items, totalPages, page, totalItems }
        })
    }
  }
}
</script>
