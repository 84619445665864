<template>
  <q-card class="fit border">
    <q-card-section class="row items-center q-py-sm border-bottom">
      <slot name="header" />

      <q-space />

      <q-btn
        :color="color"
        text-color="white"
        size="sm"
        class="q-mr-sm"
        :label="$t('Filter')"
        no-caps
        unelevated
        @click="toggleFilter"
      />

      <q-btn
        color="orange"
        text-color="white"
        :label="`(${transactions.length}) ${$t('Export to excel')}`"
        class="q-mr-sm"
        size="sm"
        no-caps
        unelevated
        @click="downloadExcel"
      />

      <slot />
    </q-card-section>

    <filter-collapse
      :is-open="isOpen"
      :options="filterOptions"
      class="border-bottom q-py-sm"
      @submit="handleFiltersSubmit"
      @close="toggleFilter"
    />

    <q-table
      row-key="id"
      :rows-per-page-label="$t('Rows per page')"
      :rows="transactions"
      :columns="columns"
      v-model:pagination="pagination"
      :loading="transactionsLoading"
      :filter="filter"
      :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
      virtual-scroll
      binary-state-sort
      flat
      @request="onRequest"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" :props="props" class="text-subtitle1" auto-width>
            <strong>{{ props.row.id }}</strong>
          </q-td>

          <q-td
            key="rateModifier"
            :props="props"
            class="text-subtitle1"
            auto-width
          >
            <div v-if="props.row._embedded && props.row._embedded.rateModifier">
              {{ props.row._embedded.rateModifier.name }}
            </div>
          </q-td>

          <q-td
            key="comment"
            :props="props"
            class="text-caption"
            style="max-width: 350px; white-space: pre-wrap !important"
          >
            {{ props.row.comment }}
          </q-td>

          <q-td key="transactionDate" :props="props" auto-width>
            {{
              $moment(
                props.row.transactionDate &&
                  typeof props.row.transactionDate === "object"
                  ? props.row.transactionDate.date
                  : props.row.transactionDate
              ).format(appOptions.formats.date)
            }}
          </q-td>

          <q-td
            key="rateModifierDetails"
            :props="props"
            class="text-subtitle1"
            auto-width
          >
            <div v-if="props.row._embedded && props.row._embedded.rateModifier">
              <q-badge
                :color="
                  rateColors[props.row._embedded.rateModifier.code] ||
                  rateColors.custom
                "
                text-color="dark"
                class="q-pa-sm"
              >
                {{
                  $t(
                    feeNames[props.row._embedded.rateModifier.code] ||
                      props.row._embedded.rateModifier.code || '--'
                  )
                }}
              </q-badge>
            </div>
          </q-td>

          <q-td key="entityID" :props="props" auto-width>
              {{props.row.entity}}
          </q-td>
          <q-td key="tax" :props="props" auto-width>
            {{ props.row.tax }}
          </q-td>

          <q-td key="value" :props="props" auto-width>
            {{ props.row.value }}
          </q-td>

          <q-td key="state" :props="props" auto-width>
            <q-badge
              :color="stateColors[props.row.state]"
              text-color="dark"
              class="q-pa-sm"
            >
              {{ $t(props.row.state) }}
            </q-badge>
          </q-td>
        </q-tr>
      </template>
        <template v-slot:bottom-row>
            <q-tr>
                <q-td class="border-top border-right text-center">
                    {{ $t('Total') }}
                </q-td>

                <q-td class="border-top"/>

                <q-td class="border-top"/>

                <q-td class="border-top"/>

                <q-td class="border-top"/>

                <q-td class="border-top"/>

                <q-td class="border-top"/>

                <q-td class="border-top">
                    <strong>
                        {{ totalVolume.toFixed(2) }}
                    </strong>
                </q-td>

                <q-td class="border-top"/>
            </q-tr>
        </template>
    </q-table>
  </q-card>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Components
import FilterCollapse from '../filters/FilterCollapse.vue'

export default {
  name: 'Transactions',
  emits: ['first-load'],
  components: {
    FilterCollapse
  },
  props: {
    flat: {
      type: Boolean,
      default () {
        return false
      }
    },
    account: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      filter: '',
      totalVolume: 0,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 250,
        rowsNumber: 250
      },
      rateColors: {
        'acceptance.taxation': 'purple-6',
        'picking.taxation': 'deep-purple-6 text-white',
        'assembling.taxation': 'blue-6',
        'shipping.taxation': 'cyan-6',
        'return.taxation': 'teal-6',
        'warehousing.taxation': 'light-green-6',
        custom: 'orange-6'
      },
      feeNames: {
        'acceptance.taxation': 'Purchase orders',
        'picking.taxation': 'Picking',
        'assembling.taxation': 'Packing',
        'shipping.taxation': 'Shipments',
        'return.taxation': 'Returns',
        'warehousing.taxation': 'Storage',
        payment: 'Payment',
        interactions: 'Interactions'
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Charge name'),
          name: 'rateModifier',
          align: 'left'
        },
        {
          label: this.$t('Comment'),
          name: 'comment',
          align: 'left'
        },
        {
          label: this.$t('Date'),
          name: 'transactionDate',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Type'),
          name: 'rateModifierDetails',
          align: 'left'
        },
        {
          label: this.$t('Entity ID'),
          name: 'entityID',
          align: 'left'
        },
        {
          label: this.$t('Tax'),
          name: 'tax',
          align: 'left'
        },
        {
          label: this.$t('Value'),
          name: 'value',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          sortable: true
        }
      ],
      stateColors: {
        new: 'green',
        confirmed: 'yellow'
      },
      isOpen: true,
      statuses: [
        { id: 'new', title: 'New' },
        { id: 'confirmed', title: 'Confirmed' }
      ],
      activatedFields: [
        'id',
        'state',
        'transactionDate.from',
        'transactionDate.to',
        'rateModifier'
      ],
      order: null,
      filters: [
        { type: 'eq', field: 'account', value: (this.account || {}).id }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'totalTransactionsNumber',
      'transactionsLoading',
      'transactions',
      'appOptions'
    ]),
    filterOptions () {
      const options = {
        defaultFilter: this.filters,
        fields: this.activatedFields,
        schema: this.externalSchema,
        query: {
          rateModifier: [
            { type: 'eq', field: 'state', value: 'active' }
          ]
        },
        values: {
          states: this.statuses
        },
        style: {
          noGroups: true,
          flat: true,
          allOnThird: true
        }
      }

      if (this.account && this.account._embedded.rate) {
        options.query.rateModifier.push({ type: 'eq', field: 'rate', value: this.account._embedded.rate.id })
      }

      return options 
    },
    externalSchema () {
      return [
        {
          type: 'input',
          label: this.$t('Order'),
          wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
          value: this.order,
          icon: 'search',
          onChange: (value) => {
            this.order = value
          }
        }
      ]
    },
    color () {
      return this.filters.filter(x => x.field !== 'account').length > 0
        ? 'light-blue-7'
        : 'dark'
    }
  },
  watch: {
    account: {
      handler (newValue, oldValue) {
        if ((newValue && oldValue && newValue.id !== oldValue.id) || (newValue && !oldValue)) {
          this.loadDefaultItems()
        }
      }
    }
  },
  mounted () {
    if (!this.account) {
      return
    }

    this.loadDefaultItems()
      .then(() => {
        this.$emit('first-load')
      })
  },
  methods: {
    ...mapActions([
      'loadTransactions'
    ]), 
    downloadExcel () {
      const query = buildQuery(this.pagination)
      query.filter = this.filters

      if (this.order) {
        query.filter = [
          ...query.filter,
          { type: 'eq', field: 'entityType', value: this.$entities.Orderadmin_Products_Entity_Order },
          { type: 'eq', field: 'entity', value: this.order }
        ]
      }

      return this.$service.transaction.downloadAll(query)
    },
    handleFiltersSubmit (filter) {
      this.filters = filter.map(e=>{
        if(e.field === 'transactionDate') {
          e.value = e.value.split('T')[0]
        }
        return e
      })


      if (this.filters.length <= 0) {
        this.order = null
      }

      this.isOpen = false
      return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilter () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      const pagination = {
        per_page: 250,
        page: 1,
        'order-by': [
          { type: 'field', field: 'transactionDate', direction: 'desc' }
        ]
      }

      return this.onRequest({ pagination })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = this.filters

      if (this.order) {
        query.filter = [
          ...query.filter,
          { type: 'eq', field: 'entityType', value: this.$entities.Orderadmin_Products_Entity_Order },
          { type: 'eq', field: 'entity', value: this.order }
        ]
      }

      return this.loadTransactions(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
          this.totalVolume = 0
          items.forEach(e=>{
            this.totalVolume += e.value
            return
          })

          return { items, totalPages, page, totalItems }
        })
    },
  }
}
</script>
